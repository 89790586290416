import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'pascalToSnake',
})
export class PascalToSnakePipe implements PipeTransform {
  transform(pascalCase: string): string {
    return pascalCase
      .split(/\.?(?=[A-Z])/)
      .join('_')
      .toUpperCase()
  }
}
