import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatDialogModule } from '@angular/material/dialog'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatSelectModule } from '@angular/material/select'
import { MatStepperModule } from '@angular/material/stepper'
import { MatChipsModule } from '@angular/material/chips'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatTableModule } from '@angular/material/table'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatListModule } from '@angular/material/list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatBadgeModule } from '@angular/material/badge'
import { TranslateModule } from '@ngx-translate/core'
import { HttpClientModule } from '@angular/common/http'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { FooterComponent } from 'src/app/components/footer/footer.component'
import { MatCardModule } from '@angular/material/card'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatMenuModule } from '@angular/material/menu'
import { ChartsModule } from 'ng2-charts'
import { MatRippleModule } from '@angular/material/core'
import { MatSortModule } from '@angular/material/sort'
import { RouterModule } from '@angular/router'
import { SideNavComponent } from 'src/app/components/side-nav/side-nav.component'
import { ConvertDatePipe } from 'src/app/pipes/convert-date.pipe'
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component'
import { ToolbarComponent } from 'src/app/components/toolbar/toolbar.component'
import { NoDataToListComponent } from 'src/app/components/no-data-to-list/no-data-to-list.component'
import { ForClaimDirective } from 'src/app/directives/for-claim.directive'
import { PascalToSnakePipe } from 'src/app/pipes/pascal-to-snake.pipe'
import { AppendPrefixPipe } from 'src/app/pipes/append-prefix.pipe'
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component'
import { SystemErrorComponent } from 'src/app/components/system-error/system-error.component'
import { TableSettingsMenuComponent } from 'src/app/components/table-settings-menu/table-settings-menu.component'
import { TitleLetterPipe } from 'src/app/pipes/title-letter.pipe'
import { SnToDayHourMinuteSnPipe } from 'src/app/pipes/sn-to-day-hour-minute-sn.pipe'
import { MembershipAgreementDialogComponent } from 'src/app/components/agreements/membership-agreement-dialog/membership-agreement-dialog.component'
import { KvkkClarificationAgreementDialogComponent } from 'src/app/components/agreements/kvkk-clarification-agreement-dialog/kvkk-clarification-agreement-dialog.component'
import { CommercialElectronicMessageAgreementDialogComponent } from 'src/app/components/agreements/commercial-electronic-message-agreement-dialog/commercial-electronic-message-agreement-dialog.component'

@NgModule({
  declarations: [
    FooterComponent,
    SideNavComponent,
    ToolbarComponent,
    BreadcrumbComponent,
    NoDataToListComponent,
    TableSettingsMenuComponent,
    ConfirmationDialogComponent,
    SystemErrorComponent,
    MembershipAgreementDialogComponent,
    KvkkClarificationAgreementDialogComponent,
    CommercialElectronicMessageAgreementDialogComponent,
    ConvertDatePipe,
    PascalToSnakePipe,
    TitleLetterPipe,
    ForClaimDirective,
    AppendPrefixPipe,
    SnToDayHourMinuteSnPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    RouterModule,
    MatToolbarModule,
    MatDialogModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSortModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    MatRippleModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTableModule,
    MatExpansionModule,
    ChartsModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatListModule,
    MatTabsModule,
    MatSnackBarModule,
    MatPaginatorModule,
    HttpClientModule,
    MatCheckboxModule,
    MatStepperModule,
    DragDropModule,
    MatTooltipModule,
    MatBadgeModule,
    MatChipsModule,
    MatButtonToggleModule,
  ],
  exports: [
    //modules
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    DragDropModule,
    MatSlideToggleModule,
    RouterModule,
    MatButtonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRippleModule,
    MatListModule,
    MatTabsModule,
    MatPaginatorModule,
    MatChipsModule,
    MatExpansionModule,
    HttpClientModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCardModule,
    ChartsModule,
    MatBadgeModule,
    MatButtonToggleModule,

    //components
    FooterComponent,
    ToolbarComponent,
    SideNavComponent,
    ConfirmationDialogComponent,
    SystemErrorComponent,
    NoDataToListComponent,
    TableSettingsMenuComponent,
    BreadcrumbComponent,
    MembershipAgreementDialogComponent,
    KvkkClarificationAgreementDialogComponent,
    CommercialElectronicMessageAgreementDialogComponent,

    //pipes
    ConvertDatePipe,
    PascalToSnakePipe,
    AppendPrefixPipe,
    TitleLetterPipe,
    SnToDayHourMinuteSnPipe,

    //directives
    ForClaimDirective,
  ],
})
export class SharedModule {}
