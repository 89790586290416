import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { SharedService } from '../services/shared.service'

@Directive({
  selector: '[forClaim]',
})
export class ForClaimDirective {
  @Input() forClaim!: string[]
  isVisible = false

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly sharedService: SharedService,
  ) {}
  ngOnInit() {
    //We subscribe to the claim$ to know the claims the user has
    this.sharedService.getClaims().subscribe((claim) => {
      // If he doesn't have any claim, we clear the viewContainerRef
      if (!claim) {
        this.viewContainerRef.clear()
      }

      // If the user has the claim needed to
      // render this component we can add it
      if (this.forClaim?.some((item) => claim.includes(item)) || !this.forClaim) {
        // If it is already visible (which can happen if
        // his claims changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the
          // templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true
          this.viewContainerRef.createEmbeddedView(this.templateRef)
        }
      } else {
        // If the user does not have the claim,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false
        this.viewContainerRef.clear()
      }
    })
  }
}
