export const CLAIMS = {
  ADMINISTRATOR_DASHBOARD: 'Administrator.AdministratorDashboard',
  TENANT_MANAGEMENT_WRITE: 'Administrator.TenantManagementWrite',
  TENANT_MANAGEMENT_READ: 'Administrator.TenantManagementRead',
  USER_MANAGEMENT_WRITE: 'Administrator.UserManagementWrite',
  USER_MANAGEMENT_READ: 'Administrator.UserManagementRead',
  ROLE_MANAGEMENT_WRITE: 'Administrator.RoleManagementWrite',
  ROLE_MANAGEMENT_READ: 'Administrator.RoleManagementRead',
  SERVER_MANAGEMENT_WRITE: 'Administrator.ServerManagementWrite',
  SERVER_MANAGEMENT_READ: 'Administrator.ServerManagementRead',
}
