import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'snToDayHourMinuteSn',
})
export class SnToDayHourMinuteSnPipe implements PipeTransform {
  transform(sn: number): string {
    let second = Math.floor(sn / (3600 * 24))
    let minute = Math.floor((sn % (3600 * 24)) / 3600)
    let hour = Math.floor((sn % 3600) / 60)
    let day = Math.floor(sn % 60)

    return `${day} day ${hour} hour ${minute} minute ${second} second`
  }
}
