import { Component, OnInit } from '@angular/core'
import { AgreementService } from 'src/app/services/agreement.service'

@Component({
  selector: 'app-commercial-electronic-message-agreement-dialog',
  templateUrl: './commercial-electronic-message-agreement-dialog.component.html',
  styleUrls: ['./commercial-electronic-message-agreement-dialog.component.css'],
})
export class CommercialElectronicMessageAgreementDialogComponent implements OnInit {
  agreement!: string

  constructor(private readonly _agreementService: AgreementService) {}

  ngOnInit(): void {
    this.loadData()
  }

  loadData(): void {
    this._agreementService.getLatestAgreement(3).subscribe((response) => {
      if (!response.success) {
        return
      }

      this.agreement = response.data.content
    })
  }
}
