import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { SharedModule } from './modules/shared/shared.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { AuthInterceptor } from './interceptors/authInterceptor'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StatisticEffects } from './store/effects/statistic.effect'
import { StatisticReducer } from './store/reducers/statistic.reducer'
import { ConfigService } from './services/config.service'

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export function initConfig(configService: ConfigService) {
  return () => configService.loadConfig()
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      statisticState: StatisticReducer,
    }),
    EffectsModule.forRoot([StatisticEffects]),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [ConfigService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
