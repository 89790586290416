import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-no-data-to-list',
  templateUrl: './no-data-to-list.component.html',
  styleUrls: ['./no-data-to-list.component.css'],
})
export class NoDataToListComponent implements OnInit {
  @Input()
  noDataToListDesc: string = ''

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}
}
