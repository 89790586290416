import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import decode from 'jwt-decode'
import { Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private titleService: Title) {}

  saveUserToken(token: string) {
    localStorage.setItem('tk', token)
  }

  getUserToken() {
    return localStorage.getItem('tk')
  }

  removeUserToken() {
    localStorage.removeItem('tk')
  }

  getPageTitle(): string {
    return this.titleService.getTitle()
  }

  setPageTitle(title: string) {
    this.titleService.setTitle(title)
  }

  getTableDisplaySettings(): any[] {
    return JSON.parse(localStorage.getItem('tableDisplaySettings')!)
  }

  getTableDisplaySettingsByName(tableName: string) {
    return this.getTableDisplaySettings()?.find((element) => element.name == tableName)
  }

  setTableDisplayColumns(tableType: string, columns: string[]) {
    let currentSettings = this.getTableDisplaySettings()
    let isTableTypeSettingValid = this.getTableDisplaySettingsByName(tableType)
    if (isTableTypeSettingValid.length > 0) {
      currentSettings = currentSettings.map((element) => (element.name == tableType ? { ...element, columns } : element))
    } else {
      currentSettings.push({ name: tableType, columns })
    }
    localStorage.setItem('tableDisplaySettings', JSON.stringify(currentSettings))
  }

  getSiteSunDarkMode() {
    return localStorage.getItem('mode') ?? 'light'
  }

  setSiteSunDarkMode(mode: string) {
    localStorage.setItem('mode', mode)
  }

  getClaims(): Observable<string[]> {
    let token = this.getUserToken()!
    let tokenPayload: any = decode(token)
    return of(tokenPayload.authorities)
  }

  getUserInformation() {
    let token = this.getUserToken()!
    let tokenPayload: any = decode(token)
    return tokenPayload
  }
}
