import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'
import { SharedService } from '../services/shared.service'

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _sharedService: SharedService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      if (this._sharedService.getUserToken()) {
        this._authService
          .isAuthenticated()
          .toPromise()
          .then(
            (response) => {
              if (response?.success) {
                this._router.navigate(['/admin'])
                resolve(false)
              } else {
                resolve(true)
              }
            },
            (error) => {
              resolve(true)
            },
          )
      } else {
        resolve(true)
      }
    })
  }
}
