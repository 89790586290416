import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DataResult } from '../dtos/http/dataResult'
import { ConfigService } from './config.service'
import { GetAgreementDto } from '../dtos/agreement/getAgreementDto'
import { GetAgreementTypeDto } from '../dtos/agreement/getAgreementTypeDto'
import { AddAgreementTypeDto } from '../dtos/agreement/addAgreementTypeDto'
import { AddAgreementDto } from '../dtos/agreement/addAgreementDto'

@Injectable({
  providedIn: 'root',
})
export class AgreementService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService,
  ) {}

  getAgreementTypes(): Observable<DataResult<GetAgreementTypeDto[]>> {
    return this._httpClient.get<DataResult<GetAgreementTypeDto[]>>(`${this._configService.getBaseUrl()}/agreement-types`)
  }

  getLatestAgreement(agreementTypeId: number): Observable<DataResult<GetAgreementDto>> {
    return this._httpClient.get<DataResult<GetAgreementDto>>(`${this._configService.getBaseUrl()}/agreement-types/${agreementTypeId}`)
  }

  getAgreement(agreementTypeId: number, agreementId: number): Observable<DataResult<GetAgreementDto>> {
    return this._httpClient.get<DataResult<GetAgreementDto>>(`${this._configService.getBaseUrl()}/agreement-types/${agreementTypeId}/agreements/${agreementId}`)
  }

  getAgreementHistory(agreementTypeId: number): Observable<DataResult<GetAgreementDto[]>> {
    return this._httpClient.get<DataResult<GetAgreementDto[]>>(`${this._configService.getBaseUrl()}/agreement-types/${agreementTypeId}/agreements`)
  }

  addAgreementType(agreementType: AddAgreementTypeDto): Observable<DataResult<GetAgreementTypeDto>> {
    return this._httpClient.post<DataResult<GetAgreementTypeDto>>(`${this._configService.getBaseUrl()}/agreement-types`, agreementType)
  }

  addAgreement(agreementTypeId: number, agreement: AddAgreementDto): Observable<DataResult<GetAgreementDto>> {
    return this._httpClient.post<DataResult<GetAgreementDto>>(`${this._configService.getBaseUrl()}/agreement-types/${agreementTypeId}/agreements`, agreement)
  }
}
