import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators'
import { AppState } from 'src/app/models/state/appState'
import { StatisticsService } from 'src/app/services/statistics.service'
import { AddStatisticAction, AddStatisticActionError, StatisticActionTypes } from '../actions/statistic.action'

@Injectable()
export class StatisticEffects {
  constructor(
    private actions$: Actions,
    private readonly _statisticService: StatisticsService,
    private readonly _store: Store<AppState>,
  ) {}

  loadDetailedStatistics$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(StatisticActionTypes.LOAD_DETAILED_STATISTIC),
      withLatestFrom(this._store.select((state) => state.statisticState.statistics)),
      filter(([action, hasLoaded]) => !hasLoaded),
      switchMap(() => {
        return this._statisticService.getGeneralStatistics().pipe(
          map((response) => new AddStatisticAction(response.data!!, null)),
          catchError((errorMessage) => of(new AddStatisticActionError(null, errorMessage))),
        )
      }),
    ),
  )
}
