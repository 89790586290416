import { trigger, state, style, transition, animate } from '@angular/animations'
import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { MatExpansionPanel } from '@angular/material/expansion'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { CLAIMS } from 'src/app/constants/claims'
import { SharedService } from 'src/app/services/shared.service'
import { AuthService } from 'src/app/services/auth.service'
import { SideNavItem } from 'src/app/models/componentModels/sideNavItem'
import { BreakpointObserver } from '@angular/cdk/layout'

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'in',
        style({
          overflow: 'hidden',
          width: '256px',
        }),
      ),
      state(
        'out',
        style({
          // opacity: '0',
          overflow: 'hidden',
          width: '68px',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class SideNavComponent implements OnInit {
  items: SideNavItem[] = [
    {
      title: 'HOME',
      icon: 'home',
      tooltip: 'HOME',
      isOpened: false,
      hasChild: false,
      route: '/admin',
    },
    {
      title: 'TENANTS',
      icon: 'storefront',
      tooltip: 'TENANTS',
      isOpened: false,
      hasChild: false,
      route: '/admin/tenants',
      canAccessClaims: [CLAIMS.TENANT_MANAGEMENT_READ],
    },
    {
      title: 'SERVERS',
      icon: 'dns',
      tooltip: 'SERVERS',
      isOpened: false,
      hasChild: false,
      route: '/admin/servers',
      canAccessClaims: [CLAIMS.TENANT_MANAGEMENT_READ],
    },
    {
      title: 'AGREEMENTS',
      icon: 'folder_open',
      tooltip: 'AGREEMENTS',
      isOpened: false,
      hasChild: false,
      route: '/admin/agreement-types',
      canAccessClaims: [CLAIMS.TENANT_MANAGEMENT_WRITE],
    },
    {
      title: 'REQUESTS',
      icon: 'inbox',
      tooltip: 'REQUESTS',
      isOpened: false,
      hasChild: true,
      route: '/admin/requests/',
      canAccessClaims: [CLAIMS.TENANT_MANAGEMENT_WRITE],
      childs: [
        {
          title: 'REGISTRATION_REQUESTS',
          tooltip: 'REGISTRATION_REQUESTS',
          isOpened: false,
          hasChild: false,
          route: '/admin/requests/registrations',
          canAccessClaims: [CLAIMS.USER_MANAGEMENT_READ],
        },
      ],
    },
    {
      title: 'SETTINGS',
      icon: 'settings',
      tooltip: 'SETTINGS',
      isOpened: false,
      hasChild: true,
      canAccessClaims: [CLAIMS.USER_MANAGEMENT_READ, CLAIMS.USER_MANAGEMENT_WRITE, CLAIMS.ROLE_MANAGEMENT_READ, CLAIMS.ROLE_MANAGEMENT_WRITE],
      childs: [
        {
          title: 'USER_MANAGEMENT',
          tooltip: 'USER_MANAGEMENT',
          isOpened: false,
          hasChild: false,
          route: '/admin/users',
          canAccessClaims: [CLAIMS.USER_MANAGEMENT_READ],
        },
        {
          title: 'ROLE_MANAGEMENT',
          tooltip: 'ROLE_MANAGEMENT',
          isOpened: false,
          hasChild: false,
          route: '/admin/roles',
          canAccessClaims: [CLAIMS.ROLE_MANAGEMENT_READ],
        },
        {
          title: 'PRODUCT_TYPES',
          tooltip: 'PRODUCT_TYPES',
          isOpened: false,
          hasChild: false,
          route: '/admin/product-types',
          canAccessClaims: [CLAIMS.TENANT_MANAGEMENT_WRITE],
        },
      ],
    },
  ]

  currentUser: any

  @ViewChildren('expansionGroup') expansionGroups!: QueryList<MatExpansionPanel>

  @Output() closeSidenav = new EventEmitter<void>()

  isHandset = true

  mobileQuery!: Subscription

  isExpanded = true

  constructor(
    private readonly _translate: TranslateService,
    private readonly _router: Router,
    private readonly _sharedService: SharedService,
    private readonly _authService: AuthService,
    private readonly _breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.mobileQuery = this._breakpointObserver.observe(['(max-width: 600px)']).subscribe((state) => {
      if (state.matches) {
        this.isExpanded = true
      }
      this.isHandset = state.matches
    })
    this.currentUser = this._sharedService.getUserInformation()
  }

  ngOnDestroy(): void {
    this.mobileQuery.unsubscribe()
  }

  checkIfAnySubItemSelected(group: SideNavItem) {
    return group.childs?.some((e) => this.currentRoute === e.route)
  }

  public changeLanguage(language: string) {
    this._translate.use(language)
  }

  get currentRoute() {
    return this._router.url
  }

  get width() {
    return this.isExpanded ? 256 : 68
  }

  get toggleButtonTransformExp() {
    return this.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'
  }

  toggle() {
    this.expansionGroups.forEach((g) => g.close())
    this.isExpanded = !this.isExpanded
  }

  onExpandCollapseClick() {
    if (this.isHandset) {
      this.closeSidenav.emit()
    } else {
      this.toggle()
    }
  }

  onRouterLink(row: any) {
    if (this.currentRoute === row.route) return

    if (this.isHandset) {
      this.closeSidenav.emit()
    }
  }

  logout() {
    this._authService.logout().subscribe((response) => {
      if (response.success) {
        this._sharedService.removeUserToken()
        this._router.navigate(['/login'])
      }
    })
  }
}
