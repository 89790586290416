<div [style.width.px]="width" id="sidenav" [class.expanded]="isExpanded" [@expandCollapse]="'in'">
  <a href="/admin" id="top" class="p-4">
    <img *ngIf="!isExpanded" class="m-0" id="logo-icon" src="favicon.ico" />
    <img *ngIf="isExpanded" class="m-0" id="logo-text" src="assets/images/hisimLogo.png" />
  </a>

  <div class="item-column">
    <div *ngFor="let row of items">
      <ng-container *ngIf="row.hasOwnProperty('childs'); then group; else item"> </ng-container>

      <ng-template #group>
        <mat-expansion-panel #expansionGroup class="mat-elevation-z0" [hideToggle]="!isExpanded" (opened)="isExpanded = true">
          <mat-expansion-panel-header
            *forClaim="row.canAccessClaims!!"
            [class.sub-item-selected]="checkIfAnySubItemSelected(row)"
            [matTooltip]="row.tooltip! | translate"
            matTooltipPosition="after"
            [matTooltipDisabled]="isExpanded"
          >
            <mat-icon class="material-icons-outlined">{{ row.icon }}</mat-icon>
            <span *ngIf="isExpanded" class="group-text">{{ row.title | translate }}</span>
          </mat-expansion-panel-header>

          <div class="group-column-with-divider">
            <mat-divider vertical></mat-divider>
            <div class="group-column">
              <button class="no-hover-effect" mat-button *ngFor="let item of row.childs" [class.selected]="currentRoute === item.route" [routerLink]="item.route" (click)="onRouterLink(row)">
                <span *ngIf="isExpanded" class="mat-medium">{{ item.title | translate }}</span>
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-template>

      <ng-template #item>
        <button
          class="no-hover-effect"
          [class.selected]="currentRoute === row.route"
          mat-button
          [routerLink]="row.route"
          (click)="onRouterLink(row)"
          *forClaim="row.canAccessClaims!"
          [matTooltip]="row.tooltip! | translate"
          matTooltipPosition="after"
          [matTooltipDisabled]="isExpanded"
        >
          <mat-icon class="material-icons-outlined">{{ row.icon }}</mat-icon>
          <span *ngIf="isExpanded" class="mat-medium">{{ row.title | translate }}</span>
        </button>
      </ng-template>
    </div>
  </div>
  <div class="spacer"></div>
  <div class="p-2 d-flex justify-content-center align-items-center flex-column">
    <button mat-icon-button id="expand-collapse" [style.transform]="toggleButtonTransformExp" mat-button disableRipple (click)="onExpandCollapseClick()">
      <mat-icon class="material-icons-outlined">chevron_left</mat-icon>
    </button>
    <div matRipple id="user-info" [matMenuTriggerFor]="userInfoMenu" class="w-100">
      <span id="user-icon">{{ currentUser.firstName | titleLetter }}{{ currentUser.lastName | titleLetter }}</span>
      <div *ngIf="isExpanded" class="user-info-column">
        <span style="font-weight: bold; line-height: 14px" class="mat-small">{{ currentUser.firstName }} {{ currentUser.lastName }}</span>
        <span style="line-height: 14px" class="mat-small">{{ currentUser.tenantName }}</span>
      </div>
    </div>
  </div>
  <mat-menu #userInfoMenu="matMenu" xPosition="before">
    <button routerLink="/admin/profile" mat-menu-item>
      <mat-icon class="material-icons-outlined">person</mat-icon>
      {{ 'PROFILE_PAGE.PROFILE' | translate }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="languages">
      <mat-icon class="material-icons-outlined">translate</mat-icon>
      {{ 'LANGUAGES' | translate }}
    </button>
    <button (click)="logout()" mat-menu-item>
      <mat-icon class="material-icons-outlined">logout</mat-icon>
      {{ 'LOGOUT' | translate }}
    </button>
  </mat-menu>
  <mat-menu #languages="matMenu">
    <button (click)="changeLanguage('tr')" mat-menu-item>
      <img height="20" width="25" src="assets/images/tr.svg" />
      TR
    </button>
    <button (click)="changeLanguage('en')" mat-menu-item>
      <img height="20" width="25" src="assets/images/en.svg" />
      ENG
    </button>
  </mat-menu>
</div>
