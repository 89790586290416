import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginGuard } from './guards/login.guard'

const routes: Routes = [
  //for auth routing
  {
    path: '',
    canActivate: [LoginGuard],
    loadChildren: () => import('./layouts/auth/auth.module').then((m) => m.AuthModule),
  },

  //for admin routing
  {
    path: 'admin',
    loadChildren: () => import('./layouts/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '**',
    redirectTo: '/',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
