import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DataResult } from '../dtos/http/dataResult'
import { GetDetailedStatisticsDto } from '../dtos/statistics/getDetailedStatisticsDto'
import { ConfigService } from './config.service'

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService,
  ) {}

  getGeneralStatistics(): Observable<DataResult<GetDetailedStatisticsDto>> {
    return this._httpClient.get<DataResult<GetDetailedStatisticsDto>>(`${this._configService.getBaseUrl()}/statistics/`)
  }
}
