import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DataResult } from '../dtos/http/dataResult'
import { Result } from '../dtos/http/resut'
import { User } from '../models/responseModels/user'
import { ConfigService } from './config.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _configService: ConfigService,
  ) {}

  login(data: any): Observable<DataResult<User>> {
    return this._httpClient.post<DataResult<User>>(`${this._configService.getBaseUrl()}/auth/login`, data)
  }

  logout(): Observable<Result> {
    return this._httpClient.get<Result>(`${this._configService.getBaseUrl()}/auth/logout`)
  }

  isAuthenticated(): Observable<Result> {
    return this._httpClient.get<Result>(`${this._configService.getBaseUrl()}/auth/is-authenticated`)
  }
}
