import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private readonly _translate: TranslateService) {
    this._translate.addLangs(['en', 'tr'])
    const browserLang = this._translate.getBrowserLang()
    this._translate.use(browserLang.match(/en|tr/) ? browserLang : 'en')
  }

  ngOnInit() {
  }
}
