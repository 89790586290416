import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent implements OnInit {
  confirmationTitle: string
  confirmationContent: string

  constructor(
    private readonly _dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data?: {
      confirmationTitle?: string
      confirmationContent?: string
    },
  ) {
    this.confirmationTitle = data?.confirmationTitle || 'ARE_YOU_SURE'
    this.confirmationContent = data?.confirmationContent || 'ARE_YOU_SURE_TO_MAKE_THIS_ACTION'
  }

  ngOnInit(): void {}

  onYesClick() {
    this._dialogRef.close({ status: true })
  }
}
