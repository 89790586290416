import { StatisticState } from 'src/app/models/state/appState'
import { StatisticAction, StatisticActionTypes } from '../actions/statistic.action'

const initialStatisticState: StatisticState = {
  isStatisticsLoaded: false,
}

export function StatisticReducer(state: StatisticState = initialStatisticState, action: StatisticAction | any): any {
  switch (action.type) {
    case StatisticActionTypes.ADD_DETAILED_STATISTIC:
      return {
        ...state,
        statistics: action.payload,
        error: null,
      }
    case StatisticActionTypes.ADD_DETAILED_STATISTIC_ERROR:
      return {
        ...state,
        statistics: null,
        error: action.error,
      }
    case StatisticActionTypes.LOAD_DETAILED_STATISTIC:
      return {
        ...state,
        isStatisticsLoaded: true,
        error: null,
      }
    default:
      return state
  }
}
