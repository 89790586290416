import { Action } from '@ngrx/store'
import { GetDetailedStatisticsDto } from 'src/app/dtos/statistics/getDetailedStatisticsDto'

export enum StatisticActionTypes {
  ADD_DETAILED_STATISTIC = '[Statistic] Add Detailed Statistic',
  LOAD_DETAILED_STATISTIC = '[Statistic] Load Detailed Statistic',
  ADD_DETAILED_STATISTIC_ERROR = '[Statistic] Add Detailed Statistic Error',
}

export class AddStatisticAction implements Action {
  readonly type = StatisticActionTypes.ADD_DETAILED_STATISTIC

  constructor(
    public payload: GetDetailedStatisticsDto,
    public error: null,
  ) {}
}

export class AddStatisticActionError implements Action {
  readonly type = StatisticActionTypes.ADD_DETAILED_STATISTIC_ERROR

  constructor(
    public payload: null,
    public error: string,
  ) {}
}

export class LoadStatisticAction implements Action {
  readonly type = StatisticActionTypes.LOAD_DETAILED_STATISTIC

  constructor() {}
}

export type StatisticAction = AddStatisticAction | AddStatisticActionError | LoadStatisticAction
