import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar'

export const SNACK_BAR_COMMON_STYLES = {
  SNACK_BAR_BTN_TEXT: 'CLOSE',
}

export const SNACK_BAR_SUCCESS_STYLES = {
  SNACK_BAR_DURATION: 2000,
  SNACK_BAR_SUCCESS: 'OPERATION_SUCCESS',
  SNACK_BAR_HORIZONTAL_POSITION: 'center' as MatSnackBarHorizontalPosition,
  SNACK_BAR_VERTICAL_POSITION: 'bottom' as MatSnackBarVerticalPosition,
  SNACK_BAR_PANEL_CLASS: 'snackbar-success',
}

export const SNACK_BAR_ERROR_STYLES = {
  SNACK_BAR_DURATION: 2000,
  SNACK_BAR_ERROR: 'OPERATION_FAIL',
  SNACK_BAR_HORIZONTAL_POSITION: 'center' as MatSnackBarHorizontalPosition,
  SNACK_BAR_VERTICAL_POSITION: 'bottom' as MatSnackBarVerticalPosition,
  SNACK_BAR_PANEL_CLASS: 'snackbar-error',
}
